export default {
	data() {
		return {

		};
	},
	methods: {
		displayYtVideoMixin() {
			if (this.videoLink !== '') this.$store.commit('app/ENABLE_VIDEO_INSTRUCTIONS', this.videoLink);
		}
	},
	computed: {
		hasYoutubeInstructions() {
			const route = this.getCurrentRoute();
			if (!route) return false;
			return typeof route.meta !== 'undefined' && typeof route.meta.yt !== 'undefined' && route.meta.yt.active;
		},
		videoLink() {
			return this.hasYoutubeInstructions ? this.$router.currentRoute.meta.yt.link : '';
		}
	}
};