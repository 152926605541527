import firebase from 'firebase/app';
import 'firebase/messaging';

const KEYS = {
	senderIds: {
		'localhost': '759861382519',
		'marketplace.cel.ro': '449640188130',
		'mpbeta.cel.ro': '759861382519'
	},
	vapidKeys: {
		'localhost': 'BJBhAPm5gBAS9d3zx38xptRM4Qzxe3tGEWqs3t-dG31IO-MF5VveEkqV479ynIju2xOIQSoLLayMUsHkkncu7to',
		'marketplace.cel.ro': 'BEiGW4n05kSe2Tne7Ur5pfsQ5GKaBTM8mv_cgcfwcnjPl3TOPTtWh-rMhqZh31QnWx1qO8Z9bTWyB1vcwWsxvp8',
		'mpbeta.cel.ro': 'BJBhAPm5gBAS9d3zx38xptRM4Qzxe3tGEWqs3t-dG31IO-MF5VveEkqV479ynIju2xOIQSoLLayMUsHkkncu7to'
	},
	projectIds: {
		'localhost': 'mpbeta-9a8a3',
		'marketplace.cel.ro': 'marketplace-front',
		'mpbeta.cel.ro': 'mpbeta-9a8a3'
	},
	apiKeys: {
		'localhost': 'AIzaSyBcxMNXCPbIoaZ_aT7Bi7kWZ-XB59QY6_Q',
		'marketplace.cel.ro': 'AIzaSyBLCouX1odav3HKXfn51ffti0PL_VEjpcA',
		'mpbeta.cel.ro': 'AIzaSyBcxMNXCPbIoaZ_aT7Bi7kWZ-XB59QY6_Q'
	},
	appIds: {
		'localhost': '1:759861382519:web:a2c309dabffd40e2',
		'marketplace.cel.ro': '1:449640188130:web:1c5c68b355cfefbd',
		'mpbeta.cel.ro': '1:759861382519:web:a2c309dabffd40e2'
	}
};


export default {
	data() {
		return {
			cmStatus: false,
			cmError: false,
			messaging: null,
			messagingSenderId: null,
			vapidKey: null,
			projectId: null,
			apiKey: null,
			appId: null
		};
	},
	methods: {
		async requestPermissionAsync() {
			try {
				await this.messaging.requestPermission();
			} catch (e) {
				console.log('Unable to get permission to notify.', e);
			}
		},
		async getTokenAsync() {
			try {
				const currentToken = await this.messaging.getToken();
				if (currentToken) {
					this.$store.dispatch('notifications/CheckCloudMessagingToken', currentToken);
				} else {
					// await this.requestPermissionAsync()
					return false;
				}
			} catch (err) {
				// console.log('An error occurred while retrieving token. ', err)
				return false;
			}
		},
		async setFbMessaging() {
			// Request permission
			await this.requestPermissionAsync();
			// Retrieve token
			await this.getTokenAsync();
		},
		async initVariables() {
			const hostname = window.location.hostname.replace(/\.+$/, '');
			if (KEYS.senderIds[hostname] == null) return false;
			this.messagingSenderId = KEYS.senderIds[hostname];
			if (KEYS.vapidKeys[hostname] == null) return false;
			this.vapidKey = KEYS.vapidKeys[hostname];
			if (KEYS.projectIds[hostname] == null) return false;
			this.projectId = KEYS.projectIds[hostname];
			if (KEYS.apiKeys[hostname] == null) return false;
			this.apiKey = KEYS.apiKeys[hostname];
			if (KEYS.appIds[hostname] == null) return false;
			this.appId = KEYS.appIds[hostname];
			return true;
		},
		async initMessaging() {
			try {
				const status = await this.initVariables();
				if (!status) return false;
				const firebaseApp = firebase.initializeApp({
					messagingSenderId: this.messagingSenderId,
					projectId: this.projectId,
					apiKey: this.apiKey,
					appId: this.appId
				});
				window.firebaseMessaging = firebaseApp.messaging();
				this.messaging = window.firebaseMessaging;
				this.messaging.usePublicVapidKey(
					this.vapidKey
				);
				this.setFbMessaging();
				firebaseMessaging.onMessage((payload) => {
					const notification = payload.notification;
					if (notification != null) {
						this.$store.dispatch('notifications/AlertUserForNotification', payload);
						// TODO: Add notification to list and show tooltip
					} else {
						// TODO: Call getNotifications and show tooltip
					}
					// console.log(payload)
					// console.log(notification)
				});
				this.cmStatus = true;
			} catch (err) {
				console.log(err);
				this.cmError = true;
			}
		}
	},
	computed: {
		havePermissionToInit() {
			return this.$store.state.notifications.permission;
		}
	},
	watch: {
		havePermissionToInit(val) {
			if (val && !this.cmStatus && !this.cmError) {
				this.initMessaging();
			}
		}
	},
	async mounted() {
		/**
		 * Firebase cloud messaging init
		 */
	}


};