<template>
    <div class="navbar">
        <div
            class="notifications-bar"
            :class="{ 'sidebar-opened': sidebar.opened, mobile: device === 'mobile' }"
            v-if="notificationBar"
        >
            <span class="text" v-if="device === 'mobile'"> Activeaza notificarile! </span>
            <span class="text" v-else>
                Activeaza notificarile pentru comenzi, produse si alte functii Marketplace!
            </span>
            <span class="buttons">
                <el-button type="primary" @click="activatePushNotifications">Activeaza</el-button>
                <span class="close-button" @click="removeNotificationBar">
                    <svg-icon icon-class="cancel" />
                </span>
            </span>
        </div>
        <hamburger class="hamburger-container" :toggleClick="toggleSideBar" :isActive="sidebar.opened"></hamburger>
        <!-- <breadcrumb></breadcrumb> -->
        <div class="navbar-container">
            <span class="nav-item account-manager" v-if="device !== 'mobile'"
                >Suport tehnic: <strong>mp@cel.ro</strong></span
            >
            <keep-alive>
                <span
                    class="yti-icon nav-icons nav-item animate-flicker"
                    v-if="hasYoutubeInstructions && !isMobileAndSideBarOpened"
                    @click="displayYtVideoMixin"
                >
                    <svg-icon icon-class="play-button-2" />
                </span>
            </keep-alive>
            <span class="nav-item" :title="$t('layout.notif-title')" @click="toggleNotifications">
                <el-badge :is-dot="unreadNotifications > 0" :max="10">
                    <span>
                        <svg-icon :title="$t('layout.notif-title')" icon-class="globe" />
                    </span>
                </el-badge>
            </span>
            <span class="nav-item">
                <keep-alive>
                    <account-info v-if="!isMobileAndSideBarOpened"></account-info>
                </keep-alive>
            </span>
            <span class="nav-item">
                <keep-alive>
                    <lang-select v-if="!isMobileAndSideBarOpened" class="international right-menu-item"></lang-select>
                </keep-alive>
            </span>
        </div>
        <!-- <SupportChat v-if="isChatEnabled" /> -->
        <transition name="slide-fade" mode="in-out">
            <keep-alive>
                <notifications v-if="notificationsOpen"></notifications>
            </keep-alive>
        </transition>
        <div class="new-notification-tooltip" v-if="notificationsAlert" @click="toggleNotifications">
            Ai notificari noi!
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
// const SupportChat = () => import('@/components/SupportChat');
const Notifications = () => import('./Notifications');
import cloudMessaging from '@/mixins/cloudMessaging';
import ytInstructions from '@/mixins/ytInstructions';

// const Breadcrumb = () =>
// 	import ('@/components/Breadcrumb')
const Hamburger = () => import('@/components/Hamburger');
const AccountInfo = () => import('@/components/AccountInfo');
const LangSelect = () => import('@/components/LangSelect');

export default {
    data() {
        return {
            userData: {
                name: '',
            },
            notifNum: 10,
        };
    },
    mixins: [ytInstructions, cloudMessaging],
    components: {
        // Breadcrumb,
        Hamburger,
        LangSelect,
        AccountInfo,
        // SupportChat,
        Notifications,
    },
    computed: {
        ...mapState({
            notificationsAlert: (state) => state.notifications.alert,
        }),
        ...mapGetters({
            sidebar: 'app/sidebar',
            appFocus: 'app/appFocus',
            currentLang: 'app/currentLang',
            userId: 'user/userId',
            userChatEnabled: 'user/chatEnabled',
            unreadNotifications: 'notifications/unreadNotifications',
        }),
        isChatEnabled() {
            return this.userId != null && this.userId !== '' && this.userChatEnabled;
        },
        hasTourComputed() {
            return this.hasTour();
        },
        device() {
            return this.$store.state.app.device;
        },
        isMobileAndSideBarOpened() {
            return this.device === 'mobile' && this.sidebar.opened;
        },
        notificationsOpen() {
            return this.$store.getters['notifications/notificationsOpened'];
        },
        notificationBar() {
            return this.$store.state.notifications.bar;
        },
    },
    methods: {
        toggleSideBar() {
            this.$store.dispatch('app/ToggleSideBar');
        },
        openFeedback() {
            this.$store.dispatch('app/ToggleFeedBackModal', true);
        },
        toggleNotifications() {
            this.$store.dispatch('notifications/ToggleNotifications');
        },
        removeNotificationBar() {
            this.$store.dispatch('notifications/RemoveNotificationBar');
        },
        activatePushNotifications() {
            this.$store.dispatch('notifications/ActivatePushNotifications');
        },
    },
    async created() {
        document.addEventListener(
            'visibilitychange',
            () => {
                const tabActive = !document.hidden;
                if (this.tabActive !== tabActive) {
                    this.$store.dispatch('app/setFocusOnApp', tabActive);
                }
            },
            false
        );
        try {
            await this.$store.dispatch('notifications/GetLatestNotifications');
        } catch (e) {}
    },
};
</script>
<style lang="scss" scoped>
@import './src/styles/modules/navbar.scss';

.feedback-button {
    line-height: 50px;
    height: 50px;
    float: left;
    padding: 0 10px;
}

.account-manager {
    font-size: 14px;
    color: #868686;
    border-right: 1px solid #cccccc;
    line-height: 30px !important;
    padding-right: 20px;
}

::v-deep .el-badge__content.is-fixed.is-dot {
    right: 7px;
    top: 1px;
}

::v-deep .el-badge__content.is-dot {
    height: 11px;
    width: 11px;
}

div.new-notification-tooltip {
    padding: 5px 10px;
    position: fixed;
    margin: 12px 10px;
    width: 215px;
    text-align: center;
    text-decoration: none;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.26);
    -webkit-border-radius: 5px;
    top: 53px;
    right: 55px;
    font-size: 14px;
    cursor: help;
    font-weight: bold;
    border: 1px solid #ffbfa5;
    z-index: 2019;
    color: #ff601d;
    background: #ffefe8;
}

div.new-notification-tooltip::after {
    background: #ffefe8;
    border: 1px solid #ffbfa5;
    content: ' ';
    position: absolute;
    top: -5px;
    left: 120px;
    display: block;
    height: 34px;
    width: 34px;
    box-shadow: 10px 5px 20px #555;
    -webkit-transform: rotate(-45deg);
    border-radius: 2px;
    -webkit-mask: -webkit-linear-gradient(left bottom, rgba(0, 0, 0, 0) 75%, white 75%);
}

div.new-notification-tooltip:before {
    content: '\e954';
    font-family: icomoon;
    top: 0px;
    position: absolute;
    right: 7px;
    font-size: 13px;
    cursor: pointer;
}

.notifications-bar {
    line-height: 48px;
    min-height: 48px;
    position: fixed;
    z-index: 2030;
    width: calc(100% - 68px);
    background-color: #051e34;
    color: #fff;
    padding: 0px 30px;
    top: 0;

    &.sidebar-opened {
        width: calc(100% - 256px);
    }

    &.mobile {
        width: 100%;
    }

    .buttons {
        float: right;

        .close-button {
            margin-left: 20px;
            font-size: 1.3em;
            cursor: pointer;
        }
    }
}

@media (max-width: 350px) {
    .notifications-bar {
        text-align: center;
        line-height: 35px;
        padding: 10px 20px;
    }

    .buttons {
        display: block;
    }
}
</style>
